import * as React from 'react'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import Gallery from './gallery'

const IndexPage = ({ pageContext }) =>
  <IndexLayout pageContext={pageContext}>
    <Page>
      <Gallery />
    </Page>
  </IndexLayout>

export default IndexPage
