import React, { useState } from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { Image } from '../components/Image'
import styled from '@emotion/styled'
import Page from '../components/Page'
import { Box, Flex } from '@rebass/grid/emotion'

// fixed size to make safari ~ 10.0 - works fine
// I would need a test here
const StyledImg = styled(Image)`
  display: block;
  width: 300px; 
  height: 400px; 
  margin: 5px;
  &:hover {
    opacity: .5;
  }
`

export const getAllPictures = graphql`
  query {
    images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 500) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`

export const generateURLForPreview = ({ name }) => `/full-preview/${name}`
// pattern: p_NUM_id_STRING
const RenderList = ({ images }) => {
  const sorted = images.edges.sort(({ node: { name: a } }, { node: { name: b } }) => {
    const [_1, aIdx] = a.split('_')
    const [_2, bIdx] = b.split('_')
    return +aIdx < +bIdx ? -1 : 1
  })

  return (
    <Flex justifyContent="center">
      <Flex
        width={[3 / 4]}
        flexWrap="wrap"
        alignItems="stretch"
        alignContent="stretch"
        justifyContent="center"
        pt={4}
      >
        {sorted.map((d, i) => {
          return <Link
            key={i}
            to={generateURLForPreview(d.node)}
          >
            <StyledImg
              alt={d.node.alt}
              sizes={d.node.childImageSharp.sizes}
              objPosition="center top"
            />
          </Link>
        }
        )}
      </Flex>
    </Flex>
  );
}

export default (props) => (
  <Page>
    <StaticQuery
      query={getAllPictures}
      render={RenderList}
    />
  </Page>
)